@import "config";

  .main-navigation {
    background: $colPolykum;
    margin-bottom: 1.5em;

    // reset mobile styles
    bottom: auto;
    box-shadow: none;
    height: auto;
    left: 0;
    overflow: visible;
    padding: 0;
    position: relative;
    width: auto;
    z-index: 2;

    .btn-label {
      display: none;
    }
  }

  .menu-list {
    // reset mobile styles
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    list-style: none;
    overflow: visible;
    padding: 0;
//      transition: all .3s;


    @include container;

    li {
      line-height: 2.5em;
      &:first-child {
        margin-left: -10px; // because links have a padding
      }

    }

    a {
      // reset
      border-bottom: none;
      color: #fff;
      display: block;
      padding: 0 1em;
      text-decoration: none;
    }

  }


  /* -=======================
  // = main menu
  // -=======================*/
  .sub-menu {
//    background: $colPolykum;
  }

  .sf-menu li {
    @include inline-block();
    line-height: 2.5em;
    &:first-child {
      margin-left: -10px; // because links have a padding
    }
  }

  .sub-menu {
//    width: 220px !important;
    li:first-child {
      margin-left: 0; // because links have a padding
    }
  }

  /* =Navigation
  -------------------------------------------------------------- */

  /* get rid of padding and margin off all ul's (not sure about using * here, could be better) */
  .sf-menu, .sf-menu * {
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  /* position all dropdowns off screen */
  .sf-menu ul {
    position: absolute;
    top: -999em;
    width: em(200px);
  }

    // 2nd level:
    .sf-menu .active ul {
//      top: 100%;
      background: $colPolykum;

    }

  /* style the main nav list items */
//  .sf-menu li {
//    line-height: 2.3em;
//    clear: left;
//    float: left;
//    position: relative;
//    width: 100%;
//  }
//
//    // 2nd level:
//    .sf-menu .active li {
//      clear: none;
//      width: auto;
//    }

  /* change the main nav list items on hover */
  .sf-menu li:hover {
    visibility:inherit; /* fixes IE7 'sticky bug' */
  }

  /* style all the links */
  .sf-menu a {
    color: #fff;
    display:block;
    padding: 0 .5em;
    text-decoration: none;
  }
  /* style the first drop */
  .sf-menu li li, .sf-menu li li:hover{
  //  background: none;
  //  background-image: none;
  }
  /* add a larger text indent for the first drop links */
  .sf-menu li li a{
//    text-indent: 24px;
  }
  /* add a larger text indent for the second drop links */
  .sf-menu li li li a{
//    text-indent: 36px;
  }
  /* add a larger text indent for the third drop links */
  .sf-menu li li li li a{
//    text-indent: 48px;
  }
  /* position first drop */
  .sf-menu li:hover ul {
    top: auto; /* match top ul list item height */
    position: relative;
  }
  /* make sure second drop is still off screen */
  ul.sf-menu li:hover li ul {
    position: absolute;
    top: -999em;
  }
  /* position second drop */
  ul.sf-menu li li:hover ul {
    top: auto;
    position: relative;
  }
  /* make sure third drop is still off screen */
  ul.sf-menu li li:hover li ul {
    position: absolute;
    top: -999em;
  }
  /* position third drop */
  ul.sf-menu li li li:hover ul {
    top: auto;
    position: relative;
  }

    /* set height so content isn't pushed down add z-index to keep drops above content */
    .sf-menu {
      height: 2.5em;
      z-index: 100;
    }
    /* restyle so main links are horizontally aligned */
    .sf-menu li {
      clear: none;
      width: auto; /* this will need to be adjusted for your needs */
    }
    /* new style for drop list items */
    .sf-menu li li {
      background: $colPolykum;
      clear: left;
      width: 100%;

      // FIXME:
      margin-top: -5px;

      a:hover {
        background: $colNaviBgHover;
      }
    }
    /* reset text indent on all drop a tags and set the width to 100% */
    .sf-menu li li a, .sf-menu li li li a, .sf-menu li li li li a {
      width: 100%;
    }
    /* reposition and style the first drop */
    .sf-menu li:hover ul {
      left: auto;
      position: absolute;
      top: 100%;
//      width: 100%;
      z-index: 100;
    }
    /* reposition and style the second drop */
//    ul.sf-menu li li:hover ul {
//      position: absolute;
//      top: -1px;
//      left: 100%;
//    }
//    /* reposition and style the third drop */
//    ul.sf-menu li li li:hover ul {
//      position: absolute;
//      top: -1px;
//      left: 100%;
//    }

  // Make menu sticky
  .main-navigation {
    top: 0;
    position: -webkit-sticky;
    position: sticky;

    .stuck {
      box-shadow: 0px 4px 13px #8E8E8E;
    }
  }

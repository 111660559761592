//@import "normalize/import-now";
//@import "breakpoint";
//@import "susy";
@import "../bower_components/normalize-scss/normalize.scss";
@import "compass";
@import "../bower_components/susy/sass/susy";
@import "../bower_components/breakpoint-sass/stylesheets/breakpoint";

@import "mixins/_px2em";

// Configuring Susy Defaults
$susy: (
  flow: ltr, // ltr | rtl
  output: float, // float | isolate
  math: fluid, // fluid | static (requires column-width)
  column-width: false, // false | value
  container: 85%, // length or % | auto
  container-position: center, // left | center | right | <length> [*2] (grid padding)
  last-flow: to,
  columns: 24,
  gutters: 1,
  gutter-position: after, // before | after | split | inside | inside-static (requires column-width)
  global-box-sizing: border-box, // content-box | border-box (affects inside/inside-static)
  debug: (
    image: hide,
    color: rgba(#66f, .25),
    output: background, // background | overlay
    toggle: top left,
  ),
);

$legacy-support-for-ie7: true;

$base-font-size: 16px;

@include border-box-sizing;


$gallery_layout: layout( 12 .125  fluid float after );
$nav_layout: layout( 12 0 fluid float after );
$golden_layout: layout( (1 1.618 2.618) .1 fluid float outside );

// -=============
// = Breakpoints
// -=============
//$breakpoint-to-ems: true;
@include breakpoint-set('to ems', true);

$phone-p: 319px;
$phone-l: 479px;
$tab-p: 639px;
$tab-l: 769px;
$desk: 1020px;
$x-large: 1380px;


// -=============
// = Colours
// -=============
$colPolykum: #009692;
$colPolykumLight: #00fad1;
$colGrey: #ccc;
//$colRed: (Orign.Print #9a191f) besser #b01a35 (<h1>, <a>)
$colRed: #f76e00;
//$colDark: h2 News intern: #000
$colDark: #000;
//h2 News extern: #2c8982
$colNewsExtern: $colPolykum;
$colText: #222;
$colTextLighter: #808080;
$colFooterText: #d8e9e8;
$colNaviBgHover: rgba(0, 0, 0, 0.5);

// Technologie
$colTechnology: #324a86;
$iconFaTechnology: "\f085";
// Management
$colManagement: #c83f3b;
$iconFaManagement: "\f0c0";
// Netzwerk
$colNetwork: #2e9e35;
$iconFaNetwork: "\f0ac";
// About
$colAbout: $colPolykumLight;
$iconFaAbout: "";

// -==========
// = Elements
// -==========
$topBarBorderRadius: .25rem;


//Button Verlauf rot: #bc0c3a - #d63054 und Hover umgekehrt

%bgButton {
  @include background-image(linear-gradient($colRed, darken($colRed, 30%)));
}
%bgButtonHover {
  @include background-image(linear-gradient($colRed, darken($colRed, 30%)));
}


//Test für Text: Source Sans Pro (http://www.google.com/fonts/specimen/Source+Sans+Pro)
//H1 serif font (http:
//}//www.google.com/fonts/specimen/EB+Garamond)

